.header {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--color-primary);
  padding: 1rem;
  /* border-bottom: 2px solid #ccc; */
  text-align: center;
  display: flex;
  justify-content: flex-start; /* Align items to the left */
  align-items: center;
  padding-left: 3rem;
  gap: 60px;

  /* font-weight: bold; */
  font-size: 24px;
  font-family: "Noto Serif", sans-serif;
}

@media screen and (max-width: 1024px) {
  .header {
    height: 80px;
  }
}

@media screen and (max-width: 600px) {
  .header {
    padding-left: 1rem;
    font-size: 1.2rem;
    height: 50px;
    gap: 40px;
  }
}
